$(document).on('turbolinks:load', function() {
  $("#search").on("keyup", function(e) {
    var key = e.keyCode || e.charCode;
    var input_result = this.value;
    if ((key == 8 || key == 46) && (input_result.length == 0)){
      request_search_products();
    }
  });
  $('body').on("cut", "#search",function(e) {
    request_search_products();
  });

  $("#search-form").on('submit', function(e){
    var search_word = $("#search").val();
    if(search_word == '' || search_word == undefined)
    {
      e.preventDefault();
      return  false;
    }
  });
  latest_product_slider();
  most_viewed_product_slider();

  $('body').on('click','.tetsts',function(){
    if($("#more_videos").is(':visible')){
      $("#more_videos").addClass('d-none')
      $(this).text("Laat meer zien")
    }else{
      $("#more_videos").removeClass('d-none')
      $(this).text("Laat minder zien")
    }
  });

  $('#video-modal').on('hidden.bs.modal', function (e) {
    $('iframe').attr('src', '');
  })

});

function request_search_products() {
  $.ajax ({
    type: "GET",
    url: '/home/search_product'
  }).done(function() {
    latest_product_slider();
  });
}


function latest_product_slider(){
  if($('.bbb_viewed_slider_one').length){
    var viewedSlider = $('.bbb_viewed_slider_one');

    viewedSlider.owlCarousel({
      loop:false,
      margin:30,
      autoplay:false,
      autoplayTimeout:6000,
      nav:false,
      dots:false,
      responsive:
      {
        0:{items:1},
        575:{items:1},
        768:{items:2},
        991:{items:2},
        992:{items:3},
        1024:{items:3}
      }
    });

    if($('.bbb_viewed_prev').length){
      var prev = $('.bbb_viewed_prev');
      prev.on('click', function()
      {
        viewedSlider.trigger('prev.owl.carousel');
      });
    }

    if($('.bbb_viewed_next').length){
      var next = $('.bbb_viewed_next');
      next.on('click', function(){
        viewedSlider.trigger('next.owl.carousel');
      });
    };
  };
}

function most_viewed_product_slider(){
  if($('.bbb_viewed_slider_two').length){
    var viewedSlider = $('.bbb_viewed_slider_two');

    viewedSlider.owlCarousel({
      loop:false,
      margin:30,
      autoplay:false,
      autoplayTimeout:6000,
      nav:false,
      dots:false,
      responsive:
      {
        0:{items:1},
        575:{items:1},
        768:{items:2},
        991:{items:2},
        992:{items:3},
        1024:{items:4}
      }
    });

    if($('.bbb_viewed_prev').length){
      var prev = $('.bbb_viewed_prev');
      prev.on('click', function()
      {
        viewedSlider.trigger('prev.owl.carousel');
      });
    }

    if($('.bbb_viewed_next').length){
      var next = $('.bbb_viewed_next');
      next.on('click', function(){
        viewedSlider.trigger('next.owl.carousel');
      });
    };
  };
}
